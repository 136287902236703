import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SignUpLayout from "../layout/SignUpLayout";
import { signUpFormSchema } from "../schema";
import Logo2 from "../../asset/auth/fuse-erp-blue-logo.svg";
import {
  SignupUser,
  getUtils,
  sendVerficationLink,
} from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { getUserToken } from "../../utils";

const AuthSignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const accessToken = getUserToken();

  const { companySizes } = useSelector((state) => state?.users);

  const getSizes = async () => {
    setLoading(true);
    const res = await dispatch(getUtils());
    setLoading(false);
    if (res.payload.status >= 400) {
      toast.update({
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    if (accessToken) {
      localStorage.clear();
    }
  }, [accessToken]);

  const onSubmit = async (values, actions) => {
    const toastId = toast.loading("Creating account, please wait...");
    const {
      first_name,
      last_name,
      phone,
      company_name,
      company_size,
      password,
      terms,
    } = values;

    const data = {
      first_name,
      last_name,
      phone_number: phone,
      company_name,
      company_size,
      email: values.email,
      password,
      tenant_type: "partners",
      terms,
      country_code: "+234",
    };
    const res = await dispatch(SignupUser(data));
    const responseData = res?.payload?.data.data ?? {};
    const { tenant_id, user_id, email, tenant_type } = responseData;
    if (res.payload.status < 400) {
      localStorage.setItem(
        "tenant_data",
        JSON.stringify({ tenant_id, user_id, email, tenant_type })
      );
      toast.update(toastId, {
        render: res?.payload?.data?.message || "Account created successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      const body = {
        email: email,
      };
      const response = await dispatch(sendVerficationLink(body));
      if (response.payload.status < 400) {
        // toast.update(toastId, {
        //   render:
        //     response?.payload?.message ||
        //     response?.payload?.data?.message ||
        //     "Login successfully",
        //   type: "success",
        //   isLoading: false,
        //   autoClose: 1000,
        // });
        setTimeout(() => {
          toast.update(toastId, {
            render: "Redirecting to account verification, please wait...",
            type: "info",
            isLoading: false,
            autoClose: 1000,
          });
        }, 1100);

        setTimeout(() => {
          navigate("/auth/account-verification");
        }, 2200);
      } else {
        toast.update(toastId, {
          render:
            response.payload.message === "Request failed with status code 500"
              ? "Something Went Wrong"
              : response.payload.message,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }
      // setTimeout(() => {
      //   navigate("/auth/choose-organization");
      //   actions.resetForm();
      // }, 1200);
    } else {
      // console.log("hey");
      toast.update(toastId, {
        render: res.payload.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      company_name: "",
      company_size: "",
      email: "",
      phone: "",
      password: "",
      terms: false,
    },
    validationSchema: signUpFormSchema,
    onSubmit,
  });

  useEffect(() => {
    getSizes();
  }, []);
  return (
    <SignUpLayout>
      <div className="lg:w-10/12 2xl:w-10/12 mx-auto">
        <div className="auth-logo-box lg:hidden mb-6 ">
          <img src={Logo2} alt="" />
        </div>
        <div className="w-full mx-auto pt-3">
          <div className="auth-container-box-two-content-header">
            <h4>Create a free account</h4>
            <p>For Business owners</p>
          </div>

          <div className="flex flex-col xl:flex-row auth-container-box-two-content-body gap-y-3">
            <div className="w-full">
              <div>
                <form className="row" onSubmit={handleSubmit}>
                  <div className=" mb-5 mt-2">
                    <div
                      className={`input-box ${
                        !errors.first_name &&
                        touched.first_name &&
                        values.first_name
                          ? "filled"
                          : errors.first_name && touched.first_name
                          ? "error"
                          : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="first_name"
                        autoComplete="off"
                        id="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="first_name" className="label-name">
                        <span className="label-text">First Name </span>
                      </label>
                    </div>

                    <div>
                      {errors.first_name && touched.first_name && (
                        <p className="error">{errors.first_name}</p>
                      )}
                    </div>
                  </div>
                  <div className=" mb-5 mt-2">
                    <div
                      className={`input-box ${
                        !errors.last_name &&
                        touched.last_name &&
                        values.last_name
                          ? "filled"
                          : errors.last_name && touched.last_name
                          ? "error"
                          : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="last_name"
                        autoComplete="off"
                        id="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="last_name" className="label-name">
                        <span className="label-text">Last Name </span>
                      </label>
                    </div>

                    <div>
                      {errors.last_name && touched.last_name && (
                        <p className="error">{errors.last_name}</p>
                      )}
                    </div>
                  </div>
                  <div className=" mb-5 mt-2">
                    <div
                      className={`input-box ${
                        !errors.company_name &&
                        touched.company_name &&
                        values.company_name
                          ? "filled"
                          : errors.company_name && touched.company_name
                          ? "error"
                          : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="company_name"
                        autoComplete="off"
                        id="company_name"
                        value={values.company_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="company_name" className="label-name">
                        <span className="label-text">Company Name </span>
                      </label>
                    </div>

                    <div>
                      {errors.company_name && touched.company_name && (
                        <p className="error">{errors.company_name}</p>
                      )}
                    </div>
                  </div>

                  <div className="mb-5 mt-6">
                    <div
                      className={`input-select-box ${
                        !errors.company_size &&
                        touched.company_size &&
                        values.company_size
                          ? "filled"
                          : errors.company_size && touched.company_size
                          ? "error"
                          : ""
                      }`}
                    >
                      <select
                        name="company_size"
                        id="company_size"
                        value={values.company_size}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ backgroundColor: "#ffffff !important" }}
                      >
                        <option value="" disabled>
                          --Select--
                        </option>
                        {[
                          {
                            name: "1 - 5",
                            value: "A",
                          },
                          {
                            name: "6 - 25",
                            value: "B",
                          },
                          {
                            name: "26 - 50",
                            value: "C",
                          },
                          {
                            name: "51 - 100",
                            value: "D",
                          },
                          {
                            name: "101 - 500",
                            value: "E",
                          },
                          {
                            name: "501 - 1000",
                            value: "F",
                          },
                          {
                            name: "1000+",
                            value: "G",
                          },
                        ].map((_, i) => (
                          <option key={_?.value || _?.name} value={_?.value}>
                            {_?.name}
                          </option>
                        ))}
                      </select>

                      <label htmlFor="company_size" className="label-name">
                        <span className="label-text">Company Size </span>
                      </label>
                    </div>
                    <div>
                      {errors.company_size && touched.company_size && (
                        <p className="error">{errors.company_size}</p>
                      )}
                    </div>
                  </div>

                  <div className=" mb-5 mt-6">
                    <div
                      className={`input-box ${
                        !errors.email && touched.email && values.email
                          ? "filled"
                          : errors.email && touched.email
                          ? "error"
                          : ""
                      }`}
                    >
                      <input
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="off"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="email" className="label-name">
                        <span className="label-text">Business Email </span>
                      </label>
                    </div>
                    <div>
                      {errors.email && touched.email && (
                        <p className="error">{errors.email}</p>
                      )}
                    </div>
                  </div>
                  <div className=" mb-5 mt-2">
                    <div
                      className={`input-box ${
                        !errors.phone && touched.phone && values.phone
                          ? "filled"
                          : errors.phone && touched.phone
                          ? "error"
                          : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="phone"
                        autoComplete="off"
                        id="phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="phone" className="label-name">
                        <span className="label-text">Phone number </span>
                      </label>
                    </div>

                    <div>
                      {errors.phone && touched.phone && (
                        <p className="error">{errors.phone}</p>
                      )}
                    </div>
                  </div>

                  <div className=" mb-5 mt-6">
                    <div
                      className={`input-password-box ${
                        !errors.password && touched.password && values.password
                          ? "filled"
                          : errors.password && touched.password
                          ? "error"
                          : ""
                      }`}
                    >
                      <input
                        type={viewPassword ? "text" : "password"}
                        name="password"
                        autoComplete="off"
                        id="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span
                        className="view"
                        style={{ cursor: "pointer" }}
                        onClick={() => setViewPassword(!viewPassword)}
                      >
                        {viewPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                      <label htmlFor="password" className="label-name">
                        <span className="label-text">Password </span>
                      </label>
                    </div>
                    <div>
                      {errors.password && touched.password && (
                        <p className="error">{errors.password}</p>
                      )}
                    </div>
                  </div>

                  <div className="mb-4 mt-6">
                    <div
                      className={`flex gap-2 items-start sm:px-4 ${
                        !errors.terms && touched.terms && values.terms
                          ? "filled"
                          : errors.terms && touched.terms
                          ? "error"
                          : ""
                      }`}
                    >
                      <input
                        className="mt-1"
                        type="checkbox"
                        name="terms"
                        id="terms"
                        value={values.terms}
                        onChange={handleChange}
                      />
                      <p className="">
                        I agree to the <Link to="/">terms of service</Link> and{" "}
                        <Link to="/">privacy policy</Link> of Fuse ERP
                      </p>
                    </div>
                    <div>
                      {errors.terms && touched.terms && (
                        <p className="error">{errors.terms}</p>
                      )}
                    </div>
                  </div>

                  <div className="submit-btn">
                    <button type="submit" disabled={isSubmitting}>
                      Create Account
                    </button>
                  </div>
                  <div className="text-center mt-2">
                    <p>
                      Already have an account?{" "}
                      <Link to="/auth/login" className="text-[#3B82F6]">
                        Sign in
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SignUpLayout>
  );
};

export default AuthSignUp;
